<template>
  <div class="page inPage iconPage">
    <div class="position">
      <i class="lee-icon lee-icon-arrow-left" @click="back"></i>
      <div class="title">Icon</div>
    </div>
    <div class="pageBody">
      <lee-cell-group title="基础用法" inset>
        <lee-icon name="search"></lee-icon>
      </lee-cell-group>
      <lee-cell-group title="使用图片 URL" inset>
        <lee-icon name="https://fastly.jsdelivr.net/npm/@vant/assets/icon-demo.png"></lee-icon>
      </lee-cell-group>
      <lee-cell-group title="图标大小" inset>
        <lee-icon name="search" :size="36"></lee-icon>
        <lee-icon name="search" :size="76"></lee-icon>
      </lee-cell-group>
      <lee-cell-group title="图标颜色" inset>
        <lee-icon name="favorite"  color="#1989fa"></lee-icon>
        <lee-icon name="fabulous" :size="36" color="#ee0a24"></lee-icon>
      </lee-cell-group>
      <lee-cell-group title="徽标提示" inset>
        <lee-icon name="fabulous" :size="36" dot></lee-icon>
        <lee-icon name="fabulous" :size="36" badge="9"></lee-icon>
        <lee-icon name="fabulous" :size="36" badge="hot"></lee-icon>
      </lee-cell-group>
    </div>
    <div class="pageFooter"><img src="@/assets/logo.png"></div>
  </div>
</template>
<script setup>
import {useRouter} from 'vue-router'

const router = useRouter()
const back = () => {
    router.push('/')
}
</script>
<style>
.iconPage .inset{background: var(--lee-white);
  padding: 16px 0;}
.iconPage .inset .lee-icon{
  margin-left: 16px;
}

</style>
